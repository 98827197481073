import { Chevron } from '@hiberworld/icons';
import styled from 'styled-components';

export const DownIcon = styled(Chevron)`
  position: absolute;
  right: 1rem;
  rotate: 180deg;
  path {
    stroke-width: 2px;
  }
  pointer-events: none;
`;

export const HiberSelect = styled.select`
  position: relative;
  width: 100%;
  text-align: left;
  padding-left: 1rem;
  outline: none;
  appearance: none;
  border-radius: 8px;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.input.default.background};
  border: none;
  box-shadow: ${({ theme }) => theme.colors.input.default.boxShadow};
  color: ${({ theme }) => theme.colors.input.default.text};
  font-size: ${({ theme }) => theme.typography.label.large.size};
  font-weight: ${({ theme }) => theme.typography.label.large.weight};
  line-height: 1;
  font-style: ${({ theme }) => theme.typography.label.large.style};

  &:hover {
    background-color: ${({ theme }) => theme.colors.input.hover.background};
    box-shadow: ${({ theme }) => theme.colors.input.hover.boxShadow};
    color: ${({ theme }) => theme.colors.input.hover.text};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.input.focus.background};
    box-shadow: ${({ theme }) => theme.colors.input.focus.boxShadow};
    color: ${({ theme }) => theme.colors.input.focus.text};
  }
  &:focus-visible {
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.input.disabled.background};
    box-shadow: ${({ theme }) => theme.colors.input.disabled.boxShadow};
    color: ${({ theme }) => theme.colors.input.disabled.text};
    cursor: not-allowed;
    + svg {
      color: ${({ theme }) => theme.colors.input.disabled.text};
    }
  }

  &:not(:focus):not(:placeholder-shown):invalid {
    background-color: ${({ theme }) => theme.colors.input.error.background};
    box-shadow: ${({ theme }) => theme.colors.input.error.boxShadow};
    color: ${({ theme }) => theme.colors.input.error.text};
  }
`;
