import { useModalActions } from 'lib/useModal';
import { FC, useEffect } from 'react';
import * as S from './Modal.styles';
import { ModalProps } from './Modal.types';

export const Modal: FC<ModalProps> = ({
  children,
  onBack,
  onClose,
  hideClose,
  keepOpenOnClickOutside,
  padding,
  closeInset,
  backInset,
  maxWidth,
}) => {
  const { close } = useModalActions();

  const handleClose = async () => {
    await onClose?.();
    close();
  };

  const handleOutsideClick = async () => {
    if (keepOpenOnClickOutside) {
      return;
    }

    await handleClose();
  };

  const onKeyDown = async event => {
    const wasEscKeyPressed = event.key === 'Escape';
    if (wasEscKeyPressed) {
      event.preventDefault();
      await handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  return (
    <S.ClickableBackground onClick={handleOutsideClick} data-testid="clickable-background">
      <S.ModalContainer padding={padding} onClick={e => e.stopPropagation()} maxWidth={maxWidth}>
        <S.OverflowWrapper>
          {children}
          {onBack && <S.BackButton onClick={onBack} inset={backInset} />}
          {!hideClose && <S.CloseButton onClick={handleClose} inset={closeInset} />}
        </S.OverflowWrapper>
      </S.ModalContainer>
    </S.ClickableBackground>
  );
};
