import { WalletSignInButtonProps } from 'views/global/Wallet/WalletSignInButton';
import dynamic from 'next/dynamic';

const WalletSignInButton = dynamic(() => import('views/global/Wallet/WalletSignInButton'), {
  ssr: false,
});

export const DynamicWalletSignInButton = ({ navigateToUsername, onCompleted }: WalletSignInButtonProps) => (
  <WalletSignInButton navigateToUsername={navigateToUsername} onCompleted={onCompleted} />
);
