import styled, { CSSProperties, useTheme } from 'styled-components';
import { Paragraph } from './Paragraph';
import { WarningTriangle } from '@hiberworld/icons';
import { FlexBox } from 'components/layouts/FlexBox';

type ErrorMessageProps = {
  message?: string | JSX.Element;
  color?: 'default' | 'contrast';
} & Pick<CSSProperties, 'marginTop' | 'marginBottom' | 'marginRight' | 'marginLeft' | 'margin'>;

export const ErrorMessage = ({ message, color, ...props }: ErrorMessageProps) => {
  const theme = useTheme();
  if (!message) {
    return null;
  }

  const ErrorTriangle = styled(WarningTriangle)`
    flex-shrink: 0;
    color: ${color === 'contrast' ? theme.colorTheme.greyscale.veryLightGrey : theme.colors.text.error};
  `;

  return (
    <FlexBox gap={'0.3rem'} {...props}>
      <ErrorTriangle size="1.4rem" title="Error" />
      <Paragraph
        size="small"
        color={color === 'contrast' ? theme.colorTheme.greyscale.veryLightGrey : theme.colors.text.error}
        role="alert">
        {message}
      </Paragraph>
    </FlexBox>
  );
};
