import { Box } from 'components/layouts/Box/Box';
import { FlexBox } from 'components/layouts/FlexBox';
import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import styled from 'styled-components';

const HiberInput = styled.input`
  box-sizing: border-box;
  border: none;
  background-color: ${({ theme }) => theme.colors.input.default.background};
  color: ${({ theme }) => theme.colors.input.default.text};
  font-size: ${({ theme }) => theme.typography.body.medium.size.web};
  font-weight: ${({ theme }) => theme.typography.body.medium.weight};
  line-height: ${({ theme }) => theme.typography.body.medium.lineHeight};
  font-style: ${({ theme }) => theme.typography.body.medium.style};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.colors.input.default.boxShadow};
  padding: 0.75rem 1rem;
  position: relative;
  resize: none;
  vertical-align: top;
  width: 100%;

  &:focus {
    background-color: ${({ theme }) => theme.colors.input.focus.background};
    box-shadow: ${({ theme }) => theme.colors.input.focus.boxShadow};
    color: ${({ theme }) => theme.colors.input.focus.text};
  }
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.input.default.placeholder};
  }

  &:invalid {
    background-color: ${({ theme }) => theme.colors.input.error.background};
    box-shadow: ${({ theme }) => theme.colors.input.error.boxShadow};
    color: ${({ theme }) => theme.colors.input.error.text};
  }

  &:hover:not(:focus):not(:disabled):not(:invalid) {
    background-color: ${({ theme }) => theme.colors.input.hover.background};
    box-shadow: ${({ theme }) => theme.colors.input.hover.boxShadow};
    color: ${({ theme }) => theme.colors.input.hover.text};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.input.disabled.background};
    box-shadow: ${({ theme }) => theme.colors.input.disabled.boxShadow};
    color: ${({ theme }) => theme.colors.input.disabled.text};

    cursor: not-allowed;
    + div > svg {
      color: ${({ theme }) => theme.colors.input.disabled.text};
    }
    &::placeholder {
      color: ${({ theme }) => theme.colors.input.disabled.text};
    }
  }
`;

const BoxWithColors = styled(Box)`
  position: relative;
  color: ${({ theme }) => theme.colors.input.default.text};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    &:hover {
      cursor: pointer;
    }
  }
`;

type TextInputIconProps = {
  Icon: ReactNode;
  onIconClick?: () => void;
};

type TextInputProps =
  | (InputHTMLAttributes<HTMLInputElement> & {
      id?: string;
      placeholder?: string;
    })
  | (InputHTMLAttributes<HTMLInputElement> & {
      id?: string;
      placeholder?: string;
    } & TextInputIconProps);

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ ...props }, ref) => {
  if ('Icon' in props) {
    const { Icon, onIconClick } = props;

    delete props?.onIconClick;

    return (
      <BoxWithColors>
        <HiberInput type="text" aria-label="text input" {...props} ref={ref} />
        <FlexBox
          role="button"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          height="100%"
          width="40px"
          top="0"
          right="0"
          padding="auto 1rem"
          onClick={onIconClick}>
          {Icon}
        </FlexBox>
      </BoxWithColors>
    );
  }

  return <HiberInput type="text" {...props} ref={ref} />;
});
