import { FetchResult } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/common/buttons/Button';
import { TextInput } from 'components/inputs/TextInput/TextInput';
import { Spacer } from 'components/layouts/Spacer';
import * as ModalStyles from 'components/legacy/modals/Modal.styles';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import { LoginInput, SigninMutation, SigninMutationResult } from 'generated/graphql';
import { useSignin } from 'lib/useSignin';
import { useSubmitForm } from 'lib/useSubmitForm';
import React from 'react';
import ErrorField from '../fields/ErrorField';
import { schema } from './LoginForm.schema';
import { LoginFormProps, LoginInputForm } from './LoginForm.types';

export const LoginForm: React.FC<LoginFormProps> = ({ onLoginSuccess, onForgotPassword }) => {
  const {
    form,
    loading: submitting,
    submitGqlForm,
  } = useSubmitForm<LoginInputForm>({
    shouldUseNativeValidation: true,
    mode: 'onSubmit',
    defaultValues: { username: '', password: '' },
    resolver: yupResolver(schema),
  });
  const signin = useSignin();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = form;

  const onSubmit = async (values: LoginInput) => {
    const { password, username } = values;
    const data = {
      username,
      password,
    };

    const queries: Promise<FetchResult<SigninMutation>>[] = [];

    queries.push(signin.signin(data));

    const res = await submitGqlForm<SigninMutation, SigninMutationResult>(queries);

    if (res.success) {
      const loginResponse = res?.data?.[0]?.data?.loginUser;
      onLoginSuccess(loginResponse);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form1" method="post">
      <TextInput {...register('username')} placeholder="Email / Username" aria-label="username" />
      <ErrorMessage marginTop="0.5rem" message={errors?.username?.message} />
      <Spacer height="1.5rem" />
      <TextInput {...register('password')} type="password" placeholder="Password" aria-label="password" />
      <ErrorMessage marginTop="0.5rem" message={errors?.password?.message} />

      <Spacer height="1rem" />
      <ModalStyles.Link fontSize="14px" color="textGray" underline onClick={onForgotPassword}>
        Forgot password?
      </ModalStyles.Link>
      <Spacer height={20} />
      <Button
        text="Log In"
        busy={submitting}
        disabled={submitting}
        type="submit"
        variety="primary"
        size="medium"
        fullWidth
        data-cy="LoginFormLoginButton"
      />
      <Spacer height="0.5rem" />
      {errors?.generic?.message && <ErrorField error={errors.generic.message} />}
    </form>
  );
};
