import { useApolloClient } from '@apollo/client';
import { CodeResponse, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { AuthButton } from 'components/common/buttons/AuthButton';
import { GoogleG } from 'components/logos/GoogleG';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import { config } from 'config';
import cookie from 'cookie';
import { AvatarProvider, useSignInWithGoogleCodeMutation } from 'generated/graphql';
import { useCreateDefaultAvatar } from 'hooks/useCreateDefaultAvatar';
import { useDive } from 'hooks/useDive';
import { setAuthCookies, setAuthLocalStorage } from 'lib/auth';
import { postMessageToEngine } from 'lib/postMessageToEngine';
import { useState } from 'react';
import { getAgeFromBirthdate } from 'utils/getAgeFromBirthdate';
import { GoogleAuthButtonProps, GoogleAuthProps, GoogleSigninProps } from './GoogleSignin.types';

const GoogleAuthButton = ({ onCompleted, loading, error }: GoogleAuthButtonProps) => {
  const [warning, setWarning] = useState<string | null>(null);
  const dive = useDive();

  const onGoogleCodeFailure = (result: Pick<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => {
    const googleError = `Sorry, Google login failed with code: ${result.error} - ${result.error_description}`;
    setWarning(googleError);
    dive.trackErrorCreatedCustom('google_signin_error', 'signin', `${result.error}:${result.error_description}`, '');
  };

  const GoogleSignIn = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: onCompleted,
    onError: onGoogleCodeFailure,
    redirect_uri: origin,
    ux_mode: 'popup',
  });

  return (
    <>
      <AuthButton
        disabled={!!error}
        onClick={GoogleSignIn}
        Icon={<GoogleG size={18} />}
        text="Continue with Google"
        busy={loading}
      />
      <ErrorMessage marginTop="0.5rem" message={(warning || error) ?? ''} />
    </>
  );
};

export const GoogleAuth = ({ onCompleted, loading }: GoogleAuthProps) => {
  const clientId = config.google.oauthId;
  const [ready, setReady] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onScriptLoadSuccess = () => {
    setReady(true);
  };
  const onScriptLoadError = () => {
    setError('Google could not load');
  };

  return (
    <GoogleOAuthProvider
      clientId={clientId}
      onScriptLoadSuccess={onScriptLoadSuccess}
      onScriptLoadError={onScriptLoadError}>
      <GoogleAuthButton loading={loading} ready={ready} error={error} onCompleted={onCompleted} />
    </GoogleOAuthProvider>
  );
};

export const GoogleSignInButton = ({ navigateToUsername, ready, error, onCompleted }: GoogleSigninProps) => {
  const [warning, setWarning] = useState<string | null>(null);
  const client = useApolloClient();
  const dive = useDive();
  const [createDefaultAvatar] = useCreateDefaultAvatar();

  const [signInWithGoogleCode, { loading }] = useSignInWithGoogleCodeMutation({
    onCompleted: async data => {
      const accessToken = data?.signInWithGoogleCode?.accessToken;
      const user = data?.signInWithGoogleCode?.user;
      const refreshToken = data?.signInWithGoogleCode?.refreshToken as string;

      if (!accessToken || !user) {
        return;
      }

      setAuthCookies({
        accessToken,
        userId: user?.id,
        refreshToken,
      });

      setAuthLocalStorage({
        accessToken,
        userId: user?.id,
        refreshToken,
      });

      postMessageToEngine({ type: 'LOGIN_WITH_DETAILS', payload: { accessToken, refreshToken } });

      const cookies = cookie.parse(document.cookie);

      dive.updateHeader({
        ...user,
        id: cookies.rid,
      });

      dive.trackUserLoggedIn({
        username: user.username,
        age: getAgeFromBirthdate(user.birthdate),
        publishedGames: user.totalPublishedGames || 0,
        loginType: 'google',
        authId: user.googleId ?? '',
        user,
      });

      dive.updateHeader(user);

      if (!user.avatarV2) {
        createDefaultAvatar({ provider: AvatarProvider.ReadyPlayerMe }); // Not awaited on purpose, to prevent blocking the 'pick username' modal
      }

      const notRegisteredOrNotAcceptedTos = !user.isRegistered || !user.hasAcceptedTos;
      if (notRegisteredOrNotAcceptedTos) {
        navigateToUsername();
        return;
      }
      await client.resetStore();
      onCompleted?.();
    },
  });

  const onGoogleCodeSuccess = async (result: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => {
    try {
      const res = await signInWithGoogleCode({ variables: { code: result.code } });

      if (res.errors && !res.data?.signInWithGoogleCode) {
        setWarning(res.errors[0].message);
        dive.trackErrorCreatedCustom('sign_in_with_google_code', 'signin', res.errors[0].message, '');
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <GoogleAuthButton loading={loading} ready={ready} error={error ?? warning} onCompleted={onGoogleCodeSuccess} />
  );
};

export const GoogleSignIn = ({ navigateToUsername, onCompleted }: GoogleSigninProps) => {
  const clientId = config.google.oauthId;
  const [ready, setReady] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onScriptLoadSuccess = () => {
    setReady(true);
  };
  const onScriptLoadError = () => {
    setError('Google could not load');
  };

  return (
    <GoogleOAuthProvider
      clientId={clientId}
      onScriptLoadSuccess={onScriptLoadSuccess}
      onScriptLoadError={onScriptLoadError}>
      <GoogleSignInButton
        navigateToUsername={navigateToUsername}
        ready={ready}
        error={error}
        onCompleted={onCompleted}
      />
    </GoogleOAuthProvider>
  );
};
