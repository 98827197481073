import { ReactNode } from 'react';
import { Button, ButtonProps } from './Button';
import { ButtonText } from 'components/typography';
import { FlexBox } from 'components/layouts/FlexBox';
import styled from 'styled-components';

type AuthButtonProps = { Icon: ReactNode; size?: ButtonProps['size'] } & Omit<ButtonProps, 'variety' | 'size'>;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.button.auth.default.background};
  color: ${({ theme }) => theme.colors.button.auth.default.text};
  border: ${({ theme }) => theme.colors.button.auth.default.border};
  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.button.auth.hover.background};
    color: ${({ theme }) => theme.colors.button.auth.hover.text};
    border: ${({ theme }) => theme.colors.button.auth.hover.border};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.button.auth.disabled.background};
    color: ${({ theme }) => theme.colors.button.auth.disabled.text};
    border: ${({ theme }) => theme.colors.button.auth.disabled.border};
  }
`;

export const AuthButton = ({ size = 'medium', text, Icon, ...props }: AuthButtonProps) => {
  return (
    <StyledButton variety="primary" size={size} {...props} fullWidth>
      <FlexBox justifyContent="space-between" alignItems="center" width="100%">
        {Icon}
        <ButtonText size={size} color="black">
          {text}
        </ButtonText>
        <div />
      </FlexBox>
    </StyledButton>
  );
};
