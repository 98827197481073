import type { FieldPolicy, TypePolicies } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';

const excludeFromCacheKey = ['cursor', 'after'];

const variableToString = (variables: Record<string, unknown>) =>
  Object.entries(variables)
    .filter(([key]) => !excludeFromCacheKey.includes(key))
    .map(([key, value]) => {
      if (typeof value === 'boolean') {
        return value ? key : `!${key}`;
      }

      if (!value) {
        return false;
      }

      if (Array.isArray(value)) {
        if (!value.length) {
          return false;
        }
        return `${key}[${value.join(',')}]`;
      }

      if (value.constructor === Object) {
        const string = variableToString(value as Record<string, unknown>);
        if (!string.length) {
          return false;
        }
        return `${key}:${string}`;
      }

      return `${key}:${value.toString()}`;
    })
    .filter(Boolean)
    .join('-');

const getCacheKey = (fieldName: string, variables: Record<string, unknown> = {}) => {
  const stringifiedVariables = variableToString(variables);
  if (!stringifiedVariables.length) {
    return fieldName;
  }

  return `${fieldName}:${variableToString(variables)}`;
};

const LOG_CACHE_KEY = false;

/**
 * this function is a way to prevent data being removed from the cache because a field on another query failed
 * for example we fetch most of the data for a project from the monolith but the current amount of users in all the sessions is fetched by a different query but still
 * under the `project {...}` query
 * if that query fails to resolve the entire project entity in the cache will be replaced with null
 * so instead we fallback to the existing data if the API returns null
 */
const preventSingletonCacheBust = () => {
  return {
    merge: (existing, incoming) => {
      return incoming || existing;
    },
  } satisfies FieldPolicy;
};

const mergeArrays = () => ({
  merge: (existing: unknown[], incoming: unknown[]) => {
    if (!existing) {
      return incoming;
    }
    return [...existing, ...incoming];
  },
});

export const pagination = ({ edgeRefPath = 'node.__ref' }: { edgeRefPath?: string } = {}) => {
  return {
    keyArgs: (_, { variables, fieldName }) => {
      const key = getCacheKey(fieldName, variables);

      if (LOG_CACHE_KEY) {
        // eslint-disable-next-line no-console
        console.log({ fieldName, variables, key });
      }

      return key;
    },
    merge: (existing, incoming) => {
      if (!existing) {
        return incoming;
      }

      const edges = [...(existing.edges ?? []), ...(incoming.edges ?? [])];
      const items = [...(existing.items ?? []), ...(incoming.items ?? [])];

      return {
        ...incoming,
        edges: uniqBy(edges, edgeRefPath),
        items: uniqBy(items, '__ref'),
      };
    },
  } satisfies FieldPolicy;
};

const userNodeRef = 'node.user.__ref';

export const typePolicies: TypePolicies = {
  User: {
    fields: {
      projects: pagination(),
      getFriends: pagination({ edgeRefPath: userNodeRef }),
      getFollowers: pagination({ edgeRefPath: userNodeRef }),
      getFollowing: pagination({ edgeRefPath: userNodeRef }),
      notifications: pagination({ edgeRefPath: '__ref' }),
      unlockedBadges: pagination(),
    },
  },
  Query: {
    fields: {
      randomizedUsernames: mergeArrays(),
      featuredFriends: pagination(),
      recommendations: pagination(),
      replies: pagination(),
      repliesV2: pagination(),
      socialFeed: pagination(),
      projects: pagination(),
      users: pagination(),
      tags: pagination(),
      project: preventSingletonCacheBust(),
    },
  },
};
