import { Shuffle } from '@hiberworld/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/common/buttons/Button';
import { TextInput } from 'components/inputs/TextInput/TextInput';
import { Spacer } from 'components/layouts/Spacer';
import { Label } from 'components/typography';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import cookie from 'cookie';
import {
  SignUpWithEmailMutation,
  SignUpWithEmailMutationResult,
  UserType,
  useRandomizedUsernamesQuery,
} from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useMe } from 'hooks/useMe';
import { gtagRecordEvent } from 'lib/gtag';
import { useSignUpWithEmail } from 'lib/useSignUpWithEmail';
import { useSubmitForm } from 'lib/useSubmitForm';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getAgeFromBirthdate } from 'utils/getAgeFromBirthdate';
import { isOverAge } from 'utils/validateAge';
import BirthDaySelect from 'views/forms/fields/BirthdaySelect';
import ErrorField from 'views/forms/fields/ErrorField';
import { schema } from './CreateAccountForm.schema';
import { CreateAccountFormData, CreateAccountFormProps } from './CreateAccountForm.types';

const CreateAccountForm = ({ onRegisterSuccess, busy }: CreateAccountFormProps) => {
  const { signUpWithEmail } = useSignUpWithEmail();
  const router = useRouter();
  const { track, trackUserCreated, trackUserSignedUp } = useDive();
  const { me } = useMe();
  const [randomCount, setRandomCount] = useState(0);
  const {
    submitGqlForm,
    loading: submitting,
    form,
  } = useSubmitForm<CreateAccountFormData>({
    mode: 'onSubmit',
    shouldUseNativeValidation: true,
    defaultValues: {
      username: me?.username ?? '',
      email: '',
      password: '',
      day: '',
      month: '',
      year: '',
      birthdate: '',
      type: UserType.Registered,
    },
    resolver: yupResolver(schema),
  });

  const { setValue, handleSubmit, register, formState } = form;
  const birthdateValue = form.getValues().birthdate;

  const { data, fetchMore } = useRandomizedUsernamesQuery({
    variables: { amount: 30 },
    onCompleted: data => {
      if (data.randomizedUsernames && data.randomizedUsernames?.length > 0) {
        setValue('username', data.randomizedUsernames[0]);
      }
    },
  });

  useEffect(() => {
    if (birthdateValue) {
      track('fieldEntered', {
        value: String(birthdateValue)?.substring(0, 10),
        page_id: router.route,
        name: 'birthdate',
      });
    }
  }, [birthdateValue, track, router.route]);

  const randomizeUsername = () => {
    if (!data?.randomizedUsernames) {
      return;
    }

    if (randomCount > data?.randomizedUsernames.length - 3) {
      fetchMore({ variables: 30 });
    }

    setValue('username', data?.randomizedUsernames[randomCount]);
    setRandomCount(randomCount + 1);
  };

  const onSubmit = async (values: CreateAccountFormData) => {
    const birthdate = new Date(values.birthdate);
    const isOverThirteen = isOverAge(birthdate);
    const email = values.email ? values.email : null;

    const payload = {
      birthdate,
      email: isOverThirteen ? email : null,
      username: values.username,
      password: values.password,
      type: UserType.Registered,
    };

    const res = await submitGqlForm<SignUpWithEmailMutation, SignUpWithEmailMutationResult>([
      signUpWithEmail({
        payload,
      }),
    ]);

    if (res.success) {
      const authResponse = res?.data[0]?.data?.createUser;
      const user = authResponse?.user;
      const cookies = cookie.parse(document.cookie);

      if (user) {
        gtagRecordEvent({ event: 'account_created', provider: email ? 'email' : 'username' });
        trackUserCreated({ guestId: cookies.rid, age: getAgeFromBirthdate(birthdate), user });
        trackUserSignedUp({ newUserId: user.id, loginType: 'user', thirdPartyId: '', username: user.username, user });
      }

      if (authResponse) {
        onRegisterSuccess(authResponse, 'email');
      }
    } else {
      form.setError('generic', {
        type: 'manual',
        message: 'Whoops, looks like something went wrong.',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form1" method="post" autoComplete="off">
      <Label size="xlarge" marginBottom="0.5rem">
        Birthday
      </Label>
      <BirthDaySelect form={form} />
      <ErrorMessage marginTop="0.5rem" message={formState?.errors?.birthdate?.message} />
      <ErrorMessage marginTop="0.5rem" message={formState?.errors?.month?.message} />
      <ErrorMessage marginTop="0.5rem" message={formState?.errors?.day?.message} />
      <ErrorMessage marginTop="0.5rem" message={formState?.errors?.year?.message} />
      <Spacer height="1.5rem" />
      <section style={{ position: 'relative' }}>
        <TextInput
          {...register('username')}
          placeholder="Username"
          aria-label="username"
          onIconClick={randomizeUsername}
          Icon={<Shuffle title="Randomize username" />}
        />
        <ErrorMessage marginTop="0.5rem" message={formState?.errors?.username?.message} />
        <Spacer height="1.5rem" />
        <TextInput {...register('email')} placeholder="Email" aria-label="email" />

        <ErrorMessage marginTop="0.5rem" message={formState?.errors?.email?.message} />
        <Spacer height="1.5rem" />
        <TextInput {...register('password')} placeholder="Password" aria-label="password" type="password" />
        <ErrorMessage marginTop="0.5rem" message={formState?.errors?.password?.message} />
      </section>
      <Spacer height="1.5rem" />

      <Button
        variety="primary"
        size="medium"
        fullWidth
        busy={submitting || busy}
        type="submit"
        text="Sign Up"
        data-cy="createAccountButton"
      />

      {form?.formState?.errors?.generic?.message && <ErrorField error={form.formState.errors.generic.message} />}
    </form>
  );
};

export default CreateAccountForm;
