import { Label } from 'components/typography';
import { User } from 'generated/graphql';
import { useMe } from 'hooks/useMe';
import { useNotificationsAmount } from 'hooks/useNotificationsAmount';
import { setAuthCookies, setAuthLocalStorage } from 'lib/auth';
import { isObject } from 'lib/utils';
import { useCallback, useEffect, useRef } from 'react';
import * as S from './NotificationIcon.styles';

type JWTData = {
  accessToken: string;
  user: User;
  login: boolean;
};

const isTokenData = (data?: unknown): data is JWTData => {
  if (!isObject(data)) {
    return false;
  }

  const { accessToken, user, login } = data;
  return typeof accessToken === 'string' && typeof user === 'object' && typeof login === 'boolean';
};

export const NotificationIcon = () => {
  const fetchNotificationsInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const me = useMe();

  const { amount, refetch: refetchNotificationsAmount, setNotificationsAmountToZero } = useNotificationsAmount();

  const refetchNotifications = useCallback(async () => {
    if (!me.isLoggedIn) {
      if (fetchNotificationsInterval.current) {
        clearInterval(fetchNotificationsInterval.current);
      }
      return;
    }

    await refetchNotificationsAmount();
  }, [me.isLoggedIn, refetchNotificationsAmount]);

  const onMessage = e => {
    if (typeof e.data !== 'string' || !e.data) {
      return;
    }
    if (e instanceof MessageEvent) {
      return;
    }

    const parsed = JSON.parse(e.data);

    if (!isTokenData(parsed)) {
      return;
    }

    const { accessToken, user, login } = parsed;

    if (login) {
      setAuthCookies({ accessToken, userId: user.id });
      // we want this so that the user is signed in when he/she exits the game
      // but now it reloads the entire page and the iframe flickers
      // props.client.resetStore();

      setAuthLocalStorage({
        accessToken,
        userId: user?.id,
      });
    }
  };

  useEffect(() => {
    if (fetchNotificationsInterval?.current) {
      fetchNotificationsInterval.current = setInterval(() => {
        refetchNotifications();
      }, 1000 * 30);
    }
    window.addEventListener('message', onMessage);

    return () => {
      if (fetchNotificationsInterval?.current) {
        clearInterval(fetchNotificationsInterval.current);
      }
      window.removeEventListener('message', onMessage);
    };
  }, [me, refetchNotifications]);

  if (amount === 0) {
    return null;
  }

  return (
    <S.Container onClick={setNotificationsAmountToZero}>
      <Label size="small">{amount}</Label>
    </S.Container>
  );
};
