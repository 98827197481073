import { Theme } from '@hiberworld/theme';
import styled, { CSSProperties } from 'styled-components';

type HeadingProps = {
  size: keyof Theme['typography']['header'];
  color?: string;
  soften?: boolean;
  maxWidth?: string;
  textAlign?: 'center' | 'left';
} & Pick<
  CSSProperties,
  'marginTop' | 'marginBottom' | 'marginRight' | 'marginLeft' | 'margin' | 'textOverflow' | 'overflowX' | 'whiteSpace'
>;

export const Heading = styled.h1.attrs<HeadingProps & { as?: 'string' | undefined }>(props => ({
  as: props?.as ?? props.size,
}))<HeadingProps>`
  /* font-family: ${({ theme, size }) => theme.typography.header[size].font.web}; */
  font-weight: ${({ theme, size }) => theme.typography.header[size].weight};
  font-size: ${({ theme, size }) => theme.typography.header[size].size.web};
  line-height: ${({ theme, size }) => theme.typography.header[size].lineHeight};
  font-style: ${({ theme, size }) => theme.typography.header[size].style};
  margin: 0;
  color: ${({ theme, color, soften }) => (color ?? soften ? theme.colors.heading.softText : theme.colors.heading.text)};
  max-width: ${({ maxWidth }) => maxWidth ?? 'none'};
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
  margin: ${({ margin }) => margin || ''};
  margin-top: ${({ marginTop }) => marginTop || ''};
  margin-bottom: ${({ marginBottom }) => marginBottom || ''};
  margin-left: ${({ marginLeft }) => marginLeft || ''};
  margin-right: ${({ marginRight }) => marginRight || ''};
  text-overflow: ${({ textOverflow }) => textOverflow || ''};
  overflow-x: ${({ overflowX }) => overflowX || ''};
  white-space: ${({ whiteSpace }) => whiteSpace || ''};
`;
