import getConfig from 'next/config';
import pkg from './package.json';

const { publicRuntimeConfig } = getConfig();

export const config = {
  environment: publicRuntimeConfig.ENV || 'development',
  captcha: {
    key: publicRuntimeConfig.CAPTCHA_SITE_KEY || '',
  },
  aws: {
    bucket: publicRuntimeConfig.AWS_BUCKET || 'hiber-cdn',
  },
  settings: {
    twitterHandle: publicRuntimeConfig.TWITTER_HANDLE || '@hiber3d',
    platformUrl: publicRuntimeConfig.APP_URL || 'https://dev.hiberdev.net',
    studioUrl: publicRuntimeConfig.STUDIO_URL || 'https://create.hiberworld.com',
    apiUrl: publicRuntimeConfig.API_URL || 'https://api.dev.hiberdev.net',
    wasmUrl: publicRuntimeConfig.PRODUCTION ? 'https://hiber.hiberworld.com' : 'https://dao.dev.hiberdev.net',
    production: publicRuntimeConfig.PRODUCTION,
    publicTestSecrets: [
      {
        name: 'Public-Test-1',
        key: 'public-test',
      },
    ],
    secret: [
      {
        name: 'Dev-Login',
        key: 'Metaverse2023',
        expires: '2023-01-18',
      },
      {
        name: 'Stage-Login',
        key: 'Vetamerse2023',
        expires: '2023-01-18',
      },
      {
        name: 'RPM-Login',
        key: 'KMp561SA4jIKW#cY',
        expires: '2023-01-18',
      },
    ],
  },
  analytics: {
    code: publicRuntimeConfig.ANALYTICS_CODE || 'UA-xxxxxxxxx-x',
  },
  app: {
    url: publicRuntimeConfig.APP_URL || 'http://localhost:4000',
  },
  cdn: publicRuntimeConfig.CDN || 'https://cdn.hibervr.com',
  web3: {
    alchemyApiKey: publicRuntimeConfig.ALCHEMY_API_KEY,
    walletConnectProjectId: publicRuntimeConfig.WALLETCONNECT_PROJECT_ID,
  },
  chat: {
    endpoint: publicRuntimeConfig.CHAT_SERVICE_ENDPOINT || 'wss://chat.dev.hiberdev.net',
  },
  stripe: {
    publishableKey: publicRuntimeConfig.STRIPE_PUBLISHABLE_KEY || '',
  },
  rpm: {
    subdomain: publicRuntimeConfig.RPM_SUBDOMAIN || 'hiber-test',
  },
  datadog: {
    applicationId: publicRuntimeConfig.DATADOG_APP_ID || '5edcb2be-96b9-420a-a190-0bd3df986a75',
    clientToken: publicRuntimeConfig.DATADOG_CLIENT_TOKEN || 'pub022361149cceb2c36e0bb435163ba860',
    sessionSampleRate: Number(publicRuntimeConfig.DATADOG_SESSION_SAMPLE_RATE || 20),
    sessionReplaySampleRate: Number(publicRuntimeConfig.DATADOG_SESSION_REPLAY_SAMPLE_RATE || 0),
    traceSampleRate: Number(publicRuntimeConfig.DATADOG_TRACE_SAMPLE_RATE || 20),
    trackFrustrations: Boolean(publicRuntimeConfig.DATADOG_TRACK_FRUSTRATIONS),
    trackUserInteractions: Boolean(publicRuntimeConfig.DATADOG_TRACK_USER_INTERACTIONS),
  },
  domain: publicRuntimeConfig.DOMAIN || 'localhost',
  google: {
    oauthId: publicRuntimeConfig.GOOGLE_OAUTH_ID || '',
  },
  sandbox: {
    url:
      publicRuntimeConfig.SANDBOX_URL ||
      'https://codesandbox.io/embed/unruffled-pascal-8158mf?fontsize=14&hidenavigation=1&theme=dark',
  },
  version: pkg.releaseVersion,
} as const;
