import { forwardRef } from 'react';
import { InputHTMLAttributes } from 'react';

type ToggleProps = {
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  backgroundColor?: string;
} & InputHTMLAttributes<HTMLInputElement>;

import styled from 'styled-components';

const StyledToggle = styled.input`
  display: block;
  padding: 0;
  transition: margin 100ms;
  appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  border: none;
  margin: 0;
  margin-left: 0;
  margin-right: 32px;
  align-self: flex-start;
  background: ${({ theme }) => theme.primaryColor.textGray};
  &:checked {
    margin-left: 16px;
    margin-right: 0px;
    background: ${({ theme }) => theme.primaryColor.green};
  }
`;

export const ToggleContainer = styled.label<{ backgroundColor?: string }>`
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  height: 16px;
  width: 32px;
  background: ${({ theme, backgroundColor }) => backgroundColor ?? theme.primaryColor.veryDark};
  border-radius: 16px;
`;

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>((props: ToggleProps, ref) => {
  return (
    <ToggleContainer htmlFor={props.id} backgroundColor={props.backgroundColor}>
      <StyledToggle {...props} id={props.id} type="checkbox" ref={ref} />
    </ToggleContainer>
  );
});
