import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/common/buttons/Button';
import { FlexBox } from 'components/layouts/FlexBox';
import { OnLoadParams, Recaptcha } from 'components/globals/Recaptcha';
import { Spacer } from 'components/layouts/Spacer';
import { ErrorMessage, Heading, Paragraph } from 'components/typography';
import { TextInput } from 'components/inputs/TextInput/TextInput';
import { phrases } from 'constants/phrases';
import { useResetPasswordRequestMutation } from 'generated/graphql';
import Head from 'next/head';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getGraphqlFieldErrors } from 'utils/getGraphqlFieldError';
import * as yup from 'yup';
import { Modal } from '../Modal';
import { ForgotPasswordFormProps, ForgotPasswordState } from './AuthModal.types';

const schema = yup.object({ emailOrUsername: yup.string().required(phrases.usernameOrEmailEmpty) });

export const ForgotPasswordModal = ({ onClose, navigateToLogin }: ForgotPasswordFormProps) => {
  const [sentEmail, setSentEmail] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [captcha, setCaptcha] = useState<OnLoadParams>({ response: '', recaptchaElement: null });
  const [resetPasswordRequest, { loading }] = useResetPasswordRequestMutation({
    onError: error => {
      setRequestError(error.message);
    },
  });

  const { handleSubmit, register, formState } = useForm<ForgotPasswordState>({
    defaultValues: {
      emailOrUsername: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: ForgotPasswordState) => {
    captcha?.recaptchaElement?.execute();

    const res = await resetPasswordRequest({
      variables: {
        email: values.emailOrUsername,
        captcha: captcha.response ?? '',
      },
    });

    if (res.errors) {
      const errorMessage = getGraphqlFieldErrors(res.errors);
      setRequestError(errorMessage);
      return;
    }

    if (!res.data?.resetPasswordRequest) {
      setRequestError('Could not find user');
      return;
    }

    setSentEmail(true);
  };

  if (sentEmail) {
    return (
      <Modal onClose={onClose}>
        <FlexBox alignItems="center" justifyContent="center">
          <Heading size="h3" as="h3" marginBottom="1rem" textAlign="center">
            Email sent!
          </Heading>
        </FlexBox>
        <Spacer height={16} />
        <Paragraph size="medium">
          It could take a moment for the email to arrive. If not received, please check your email trash folder.
        </Paragraph>
        <Spacer height={16} />
        <FlexBox justifyContent="center">
          <Button variety="primary" size="medium" text="OK" onClick={navigateToLogin} />
        </FlexBox>
      </Modal>
    );
  }

  return (
    <Modal onBack={navigateToLogin} onClose={onClose}>
      <Head>
        <title>HiberWorld - Forgot Password</title>
      </Head>
      <FlexBox height="100%" width="304px" direction="column" justifyContent="center" alignItems="center">
        <Recaptcha onLoad={setCaptcha} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Heading size="h3" as="h3" marginBottom="1rem" textAlign="center">
            Forgot Password
          </Heading>
          <TextInput
            {...register('emailOrUsername')}
            placeholder="Enter email or username"
            aria-label="email or username"
          />
          <ErrorMessage message={formState.errors.emailOrUsername?.message} />

          <Spacer height="0.5rems" />
          <Paragraph size="small">We will send you a reset password link to the provided email address.</Paragraph>
          <FlexBox justifyContent="center" alignItems="center" margin="1rem 0 ">
            <Button variety="primary" size="medium" type="submit" disabled={loading} text="Confirm" />
          </FlexBox>
          <ErrorMessage message={requestError} />
        </form>
      </FlexBox>
    </Modal>
  );
};
