import { Select } from 'components/inputs/Select/Select';
import { FlexBox } from 'components/layouts/FlexBox';
import { phrases } from 'constants/phrases';
import React, { useEffect } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

type BirthDaySelectProps = {
  // TODO: this should not be any, but will look into a solution
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, object>;
};

const isOverFive = (date: Date) => {
  const now = new Date();
  const then = date.getTime();
  now.setFullYear(now.getFullYear() - 5);
  const nowStamp = now.getTime();
  return nowStamp > then;
};

const isValidBirthdate = (year: number, month: number, day: number) => {
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  return month >= 0 && month < 12 && day > 0 && day <= daysInMonth;
};

const BirthDaySelect: React.FC<BirthDaySelectProps> = ({ form }) => {
  const { watch, setValue, setError } = form;
  const day = watch('day');
  const month = watch('month');
  const year = watch('year');

  useEffect(() => {
    const currentBirthdate = new Date(year, month, day);
    const validBirthdate = isValidBirthdate(year, month, day);
    const overFive = isOverFive(currentBirthdate);

    if (day && month && year && (!validBirthdate || !overFive)) {
      setValue('birthdate', undefined);
      setError('birthdate', { message: phrases.underAgeText });
      return;
    }
    setError('birthdate', { message: '' });
    setValue('birthdate', currentBirthdate);
  }, [day, month, setError, setValue, year]);

  return (
    <FormProvider {...form}>
      <FlexBox gap="0.5rem" justifyContent="space-between">
        <Select {...form.register('month')} name="month" aria-label="month" placeholder="Month">
          <option value="0">1</option>
          <option value="1">2</option>
          <option value="2">3</option>
          <option value="3">4</option>
          <option value="4">5</option>
          <option value="5">6</option>
          <option value="6">7</option>
          <option value="7">8</option>
          <option value="8">9</option>
          <option value="9">10</option>
          <option value="10">11</option>
          <option value="11">12</option>
        </Select>

        <Select {...form.register('day')} name="day" aria-label="day" placeholder="Day">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
          <option value="21">21</option>
          <option value="22">22</option>
          <option value="23">23</option>
          <option value="24">24</option>
          <option value="25">25</option>
          <option value="26">26</option>
          <option value="27">27</option>
          <option value="28">28</option>
          <option value="29">29</option>
          <option value="30">30</option>
          <option value="31">31</option>
        </Select>

        <Select {...form.register('year')} name="year" aria-label="year" placeholder="Year">
          {new Array(100).fill(0).map((v, i) => {
            const yearValue = new Date().getUTCFullYear() - i;
            return (
              <option key={yearValue} value={yearValue}>
                {yearValue}
              </option>
            );
          })}
        </Select>
      </FlexBox>
    </FormProvider>
  );
};

export default BirthDaySelect;
