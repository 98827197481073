import { useApolloClient } from '@apollo/client';
import { Shuffle } from '@hiberworld/icons';
import { Button } from 'components/common/buttons/Button';
import { TextInput } from 'components/inputs/TextInput/TextInput';
import { Spacer } from 'components/layouts/Spacer';
import { Heading, Paragraph } from 'components/typography';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import cookie from 'cookie';
import { useFinishRegistrationMutation, useRandomizedUsernamesLazyQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useMe } from 'hooks/useMe';
import { gtagRecordEvent } from 'lib/gtag';
import { postMessageToEngine } from 'lib/postMessageToEngine';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getAgeFromBirthdate } from 'utils/getAgeFromBirthdate';
import { getGraphqlFieldErrors } from 'utils/getGraphqlFieldError';
import { Modal } from '../Modal';
import { FinishRegistrationModalProps } from './AuthModal.types';
import * as yup from 'yup';
import { phrases } from 'constants/phrases';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
  username: yup
    .string()
    .matches(/^[\w]*$/g)
    .required(phrases.usernameEmpty)
    .min(3, phrases.usernameWrongLength)
    .max(18, phrases.usernameWrongLength),
});

export const UsernameModal = ({ onClose, onSignUpCompleted }: FinishRegistrationModalProps) => {
  const { me, loading } = useMe();
  const meUserName = me?.username ?? '';
  const client = useApolloClient();
  const dive = useDive();
  const [username, setUsername] = useState(meUserName);
  const [randomCount, setRandomCount] = useState(1);
  const [finishRegistration] = useFinishRegistrationMutation();
  const [submitting, setSubmitting] = useState(false);
  const form = useForm({
    shouldUseNativeValidation: true,
    defaultValues: { username },
    resolver: yupResolver(schema),
  });
  const [randomizeUsernames, queryResult] = useRandomizedUsernamesLazyQuery({
    onCompleted: data => {
      if (username === '' && data.randomizedUsernames) {
        const randomUsername = data.randomizedUsernames[0];
        setUsername(randomUsername);
        form.setValue('username', randomUsername);
      }
    },
  });

  useEffect(() => {
    if (submitting || loading) {
      return;
    }

    randomizeUsernames({
      variables: { amount: 30 },
    });
  }, [loading, randomizeUsernames, submitting, username]);

  const navigateAndClose = async () => {
    await client.resetStore();
    await onSignUpCompleted?.();
    onClose();
  };

  const doFinishRegistration = async (values: { username: string }) => {
    setSubmitting(true);
    const payload = {
      username: values.username,
      hasAcceptedTOS: true,
      newsletterSignup: true,
    };
    try {
      const { data, errors } = await finishRegistration({ variables: { payload } });
      const user = data?.finishRegistration;

      if (errors?.length) {
        form.setError('username', { message: getGraphqlFieldErrors(errors) });
        setSubmitting(false);
        setUsername(values.username);
        return;
      }
      const cookies = cookie.parse(document.cookie);
      if (user?.googleId) {
        gtagRecordEvent({ event: 'account_created', provider: 'google' });
        dive.trackUserCreated({ guestId: cookies.rid, age: getAgeFromBirthdate(me?.birthdate), user });
        dive.trackUserSignedUp({
          newUserId: user.id,
          loginType: 'google',
          thirdPartyId: user.googleId,
          username: user.username,
          user,
        });
      }

      // Handle users who signed up with a wallet
      if (user?.wallets && user.wallets[0]) {
        const wallet = user?.wallets[0];
        dive.trackUserCreated({ guestId: cookies.rid, age: getAgeFromBirthdate(me?.birthdate), user });
        dive.trackUserSignedUp({
          newUserId: user.id,
          loginType: 'wallet',
          thirdPartyId: wallet.address,
          idType: wallet.type,
          username: user.username,
          user,
        });
      }
    } catch (e) {
      form.setError('username', { message: 'Username already taken' });
    }
    setSubmitting(false);

    postMessageToEngine('LOGIN_SUCCESS');

    navigateAndClose();
  };

  const handleSubmit = async (values: { username: string }) => {
    await doFinishRegistration(values);
  };

  const randomizeUsername = () => {
    if (randomCount > 28) {
      queryResult.refetch?.();
    }
    if (queryResult?.data?.randomizedUsernames) {
      form.setValue('username', queryResult.data.randomizedUsernames[randomCount]);
      setRandomCount(randomCount + 1);
      if (randomCount > 28) {
        setRandomCount(0);
      }
    }
    form.clearErrors('username');
  };

  return (
    <Modal hideClose keepOpenOnClickOutside onClose={onClose}>
      <Heading size="h3" marginBottom="2rem">
        Pick a username
      </Heading>
      <Paragraph size="medium" marginBottom="2rem">
        Choose a username, but don&apos;t use your real name!
      </Paragraph>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <section style={{ position: 'relative' }}>
          <TextInput
            {...form.register('username')}
            placeholder="Don't use your real name"
            aria-label="username"
            Icon={<Shuffle />}
            onIconClick={randomizeUsername}
          />
          <ErrorMessage message={form.formState.errors.username?.message} marginTop="0.5rem" />
        </section>
        <ErrorMessage message={form.formState.errors.root?.message} marginTop="0.5rem" />
        <Spacer height={16} />
        <Button
          disabled={!form.formState.isValid}
          busy={form.formState.isSubmitting}
          variety="primary"
          size="medium"
          type="submit"
          fullWidth
          text="Save"
        />
      </form>
    </Modal>
  );
};
