import { useApolloClient } from '@apollo/client';
import { setAuthCookies, setAuthLocalStorage } from 'lib/auth';
import { postMessageToEngine } from 'lib/postMessageToEngine';

type LoginInput = {
  accessToken?: string | null;
  userId?: string | null;
  refreshToken?: string | null;
};

export const useLogin = () => {
  const apolloClient = useApolloClient();

  const login = async (loginInput: LoginInput) => {
    const accessToken = loginInput?.accessToken;
    const userId = loginInput?.userId;
    const refreshToken = loginInput?.refreshToken as string;

    if (!accessToken || !userId || !refreshToken) {
      return;
    }

    setAuthCookies({ accessToken, userId, refreshToken });

    setAuthLocalStorage({
      accessToken,
      userId,
      refreshToken,
    });

    apolloClient.cache.reset();

    postMessageToEngine({ type: 'LOGIN_WITH_DETAILS', payload: { accessToken, refreshToken } });
    postMessageToEngine('LOGIN_SUCCESS');
  };

  return { login };
};
