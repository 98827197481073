import { GoogleSignIn } from 'components/globals/GoogleSignin/GoogleSignin';
import { Spacer } from 'components/layouts/Spacer';
import { DynamicWalletSignInButton } from 'views/global/Wallet/DynamicWalletSignInButton';
import { postMessageToEngine } from 'lib/postMessageToEngine';
import { Modal } from '../Modal';
import * as S from '../Modal.styles';
import { SignUpModalProps } from './AuthModal.types';
import { DEFAULT_LOGIN_HEADLINE, DEFAULT_SIGN_UP_HEADLINE } from './AuthModal';
import { useDive } from 'hooks/useDive';
import { AuthButton } from 'components/common/buttons/AuthButton';
import { User } from '@hiberworld/icons';
import { useTheme } from 'styled-components';

export const SignUpModal = ({
  navigateToSignupWithEmail,
  navigateToLogin,
  navigateToUsername,
  hideLoginOption,
  onClose,
  title,
  onSignUpCompleted,
}: SignUpModalProps) => {
  const dive = useDive();
  const theme = useTheme();
  const onAlternateSignUpComplete = async () => {
    postMessageToEngine('LOGIN_SUCCESS');
    await onSignUpCompleted?.();
    onClose?.();
  };

  const onNavigateToLogin = () => {
    dive.trackModalOpen({ name: 'login', origin: 'sign_up', description: DEFAULT_LOGIN_HEADLINE });
    navigateToLogin();
  };

  const onNavigateToSignupWithEmail = () => {
    dive.trackModalOpen({ name: 'sign_up_with_email', origin: 'sign_up', description: DEFAULT_SIGN_UP_HEADLINE });
    navigateToSignupWithEmail();
  };

  const onNavigateToUsername = () => {
    dive.trackModalOpen({ name: 'username', origin: 'sign_up', description: 'Pick a username' });

    navigateToUsername();
  };

  return (
    <Modal onClose={onClose}>
      <S.RpmFrontLight alt="Sign up avatar" src="https://cdn.hibervr.com/static/images/Rpm_02_FrontLight.png" />
      {title ? <S.Headline>{title}</S.Headline> : <S.Headline>{DEFAULT_SIGN_UP_HEADLINE}</S.Headline>}
      {title ? '' : <S.Text>Create an account to play, create, hang out with friends and more.</S.Text>}
      <Spacer height={40} />
      <AuthButton
        onClick={onNavigateToSignupWithEmail}
        Icon={<User size={24} color={theme.primaryColor.appBlack} />}
        text={'Sign up with email'}
      />
      <Spacer height={20} />
      <GoogleSignIn navigateToUsername={onNavigateToUsername} onCompleted={onAlternateSignUpComplete} />
      <Spacer height={20} />
      <DynamicWalletSignInButton navigateToUsername={onNavigateToUsername} onCompleted={onAlternateSignUpComplete} />
      <Spacer height={16} />
      <S.Text fontSize="12px" lineHeight="18px">
        By continuing, you are agreeing to the HiberWorld{' '}
        <S.Link
          fontSize="12px"
          bold
          color="red"
          target="_blank"
          rel="noreferrer"
          href="https://hiber3d.com/terms-of-use/">
          Terms of Use
        </S.Link>{' '}
        and{' '}
        <S.Link
          fontSize="12px"
          bold
          color="red"
          target="_blank"
          rel="noreferrer"
          href="https://hiber3d.com/privacy-policy/">
          Privacy Policy
        </S.Link>
      </S.Text>
      {!hideLoginOption && (
        <>
          <Spacer height={40} />
          <S.Text fontSize="16px" onClick={onNavigateToLogin} onKeyDown={onNavigateToLogin} role="button" tabIndex={0}>
            Already have an account?{' '}
            <S.Link fontSize="16px" color="white" bold>
              Log In
            </S.Link>
          </S.Text>
        </>
      )}
    </Modal>
  );
};
