import { theme } from '@hiberworld/theme';

const breakpoint = {
  large: '1366px',
  mediumLarge: '1024px',
  medium: '768px',
  mediumSmall: '568px',
  small: '430px',
  extraSmall: '320px',
  tabletLandscape: '900px',
  tabletPortrait: '700px',
  breakMobile: '830px',
  top: '830px',
} as const;

const colorTheme = {
  primary: {
    main: '#E02B36',
    dark: '#D6212C',
    light: '#EA3540',
  },
  accents: {
    green: {
      primary: '#09B584',
    },
    purple: {
      primary: '#6424CC',
    },
  },
  greyscale: {
    white: '#FFFFFF',
    veryLightGrey: '#E9E9EC',
    lightGrey: '#A7A9AF',
    mediumGrey: '#909398',
    dark: '#454654',
    veryDark: '#343540',
    almostBlack: '#292A31',
    hiberBlack: '#1B1C20',
  },
  rarity: {
    legendary: {
      dark: '#343026',
      main: '#9B7D30',
    },
    epic: {
      dark: '#2B2633',
      main: '#613A9F',
    },
    rare: {
      dark: '#232930',
      main: '#2A5F9E',
    },
    uncommon: {
      dark: '#233127',
      main: '#369851',
    },
    common: {
      dark: '#2D3439',
      main: '#454654',
    },
  },
  // Ask UX about these colors whenever we want to remove them.
  // Add any random colors that exists in the project but don't appear in the figma designs to this list.
  deprecated: {
    yellow: {
      primary: '#F5D667',
    },
  },
} as const;

const colors = {
  background: colorTheme.greyscale.hiberBlack,
  text: {
    default: colorTheme.greyscale.white,
    error: colorTheme.primary.main,
    disabled: colorTheme.greyscale.lightGrey,
    info: colorTheme.greyscale.lightGrey,
    success: colorTheme.accents.green.primary,
  },
  input: {
    default: {
      background: colorTheme.greyscale.veryDark,
      boxShadow: 'none',
      text: colorTheme.greyscale.white,
      placeholder: colorTheme.greyscale.lightGrey,
      accent: colorTheme.primary.main,
    },
    hover: {
      background: colorTheme.greyscale.veryDark,
      boxShadow: `inset 0 0 0 1px ${colorTheme.greyscale.dark}`,
      text: colorTheme.greyscale.white,
      placeholder: colorTheme.greyscale.lightGrey,
    },
    focus: {
      background: colorTheme.greyscale.veryDark,
      boxShadow: `inset 0px -3px 0 -1px ${colorTheme.greyscale.lightGrey}`,
      text: colorTheme.greyscale.white,
      placeholder: colorTheme.greyscale.lightGrey,
    },
    disabled: {
      background: colorTheme.greyscale.veryDark,
      boxShadow: `none`,
      text: colorTheme.greyscale.mediumGrey,
    },
    error: {
      background: colorTheme.greyscale.veryDark,
      boxShadow: `inset 0px -3px 0 -1px ${colorTheme.primary.main}`,
      text: colorTheme.greyscale.white,
      placeholder: colorTheme.greyscale.lightGrey,
    },
    search: {
      background: `${colorTheme.greyscale.almostBlack}66`,
      border: `1px solid ${colorTheme.greyscale.dark}`,
      focusBorder: `1px solid ${colorTheme.greyscale.mediumGrey}`,
      text: colorTheme.greyscale.veryLightGrey,
      placeholder: colorTheme.greyscale.mediumGrey,
    },
  },
  tag: {
    background: colorTheme.greyscale.veryDark,
    text: colorTheme.greyscale.white,
  },
  heading: {
    text: colorTheme.greyscale.white,
    softText: colorTheme.greyscale.veryLightGrey,
  },
  paragraph: {
    text: colorTheme.greyscale.white,
  },
  link: {
    default: {
      text: colorTheme.greyscale.white,
    },
    hover: {
      text: colorTheme.primary.main,
    },
  },
  label: {
    text: colorTheme.greyscale.white,
    limitedTime: colorTheme.primary.main,
  },
  card: {
    background: colorTheme.greyscale.almostBlack,
    borderTop: `1px solid ${colorTheme.greyscale.dark}`,
    boxShadow: `box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15)`,
  },
  dialog: {
    background: colorTheme.greyscale.almostBlack,
    border: `1px solid ${colorTheme.greyscale.dark}`,
    backdrop: `${colorTheme.greyscale.hiberBlack}B3`,
  },
  pill: {
    background: colorTheme.primary.main,
  },
  button: {
    auth: {
      default: {
        background: colorTheme.greyscale.white,
        border: 'transparent',
        text: colorTheme.greyscale.almostBlack,
      },
      hover: {
        background: colorTheme.greyscale.white,
        border: 'transparent',
        text: colorTheme.greyscale.almostBlack,
      },
      active: {
        background: colorTheme.greyscale.white,
        border: 'transparent',
        text: colorTheme.greyscale.almostBlack,
      },
      disabled: {
        background: colorTheme.greyscale.mediumGrey,
        border: 'transparent',
        text: colorTheme.greyscale.lightGrey,
      },
    },
    primary: {
      default: {
        background: colorTheme.primary.main,
        border: 'transparent',
        text: colorTheme.greyscale.white,
      },
      hover: {
        background: colorTheme.primary.light,
        border: 'transparent',
        text: colorTheme.greyscale.white,
      },
      active: {
        background: colorTheme.primary.dark,
        border: 'transparent',
        text: colorTheme.greyscale.white,
      },
      disabled: {
        background: colorTheme.greyscale.veryDark,
        border: 'transparent',
        text: colorTheme.greyscale.lightGrey,
      },
    },
    secondary: {
      default: {
        background: colorTheme.greyscale.veryDark,
        border: 'transparent',
        text: colorTheme.greyscale.white,
      },
      hover: {
        background: colorTheme.greyscale.dark,
        border: 'transparent',
        text: colorTheme.greyscale.white,
      },
      active: {
        background: colorTheme.greyscale.almostBlack,
        border: 'transparent',
        text: colorTheme.greyscale.white,
      },
      disabled: {
        background: colorTheme.greyscale.veryDark,
        border: 'transparent',
        text: colorTheme.greyscale.lightGrey,
      },
    },
    text: {
      default: {
        background: 'transparent',
        border: 'transparent',
        text: colorTheme.greyscale.white,
      },
      disabled: {
        background: 'transparent',
        border: '',
        text: colorTheme.greyscale.white,
      },
      hover: {
        background: 'transparent',
        border: colorTheme.greyscale.dark,
        text: colorTheme.greyscale.white,
      },
      active: {
        background: 'transparent',
        border: '',
        text: colorTheme.greyscale.white,
      },
    },
  },
  scrollbar: {
    default: {
      thumb: colorTheme.greyscale.veryDark,
      background: colorTheme.greyscale.hiberBlack,
    },
    hover: {
      thumb: colorTheme.primary.main,
      background: colorTheme.greyscale.hiberBlack,
    },
  },
  status: {
    online: colorTheme.accents.green.primary,
    offline: colorTheme.greyscale.white,
    playing: colorTheme.accents.green.primary,
  },
  notification: {
    icon: {
      trophy: colorTheme.deprecated.yellow.primary,
      addUser: colorTheme.accents.green.primary,
      liked: colorTheme.primary.main,
      default: colorTheme.primary.main,
    },
    background: colorTheme.greyscale.dark,
  },
  chat: {
    background: colorTheme.greyscale.almostBlack,
    header: colorTheme.greyscale.veryDark,
    hover: {
      conversation: colorTheme.greyscale.dark,
      icon: colorTheme.accents.purple.primary,
    },
    input: colorTheme.greyscale.veryDark,
    message: {
      me: colorTheme.accents.purple.primary,
      other: colorTheme.greyscale.veryDark,
    },
    messageText: {
      unread: colorTheme.greyscale.lightGrey,
    },
    muted: colorTheme.greyscale.lightGrey,
    date: colorTheme.greyscale.lightGrey,
    lineSeperator: colorTheme.greyscale.almostBlack,
    bubble: {
      background: colorTheme.greyscale.dark,
      icon: colorTheme.greyscale.white,
    },
    search: {
      background: colorTheme.greyscale.veryDark,
    },
  },
  navigation: {
    background: colorTheme.greyscale.almostBlack,
    hoverBackground: colorTheme.greyscale.veryDark,
  },
} as const;

export const Theme = {
  color: {
    background: '#1A1B20',
    red: {
      primary: '#f55151',
      secondary: '#8D3F3F',
      tertiery: '#4B2929',
    },
    blue: {
      primary: '#4379FF',
      secondary: '#3857A7',
      tertiery: '#263554  ',
    },
    teal: {
      primary: '#24C790',
      secondary: '#257C5F',
      tertiery: '#1F3732',
    },
    purple: {
      primary: '#894CEB',
      secondary: '#604592',
      tertiery: '#3E30',
    },
    yellow: {
      primary: '#F5D667',
      secondary: '#AC8E3F',
      tertiery: '#443D30',
    },
    black: {
      primary: '#151618',
      secondary: '#1a1b20',
      tertiery: '#24252f',
    },
    white: {
      primary: '#fff',
      secondary: '#80818a',
      tertiery: '#343541',
    },
  },
  spacing: {
    xSmall: '8px',
    small: '16px',
    medium: '24px',
    mediumLarge: '32px',
    large: '40px',
    gridGap: '16px',
  },
  breakpoint,
  primaryColor: {
    red: '#E81623',
    redHover: '#AE101A',
    green: '#09B584',
    greenHover: '#05A074',
    yellow: '#C8C804',
    purple: '#6424CC',
    purpleHover: '#561EB1 ',
    dark: '#292A31',
    darkHover: '#343540',
    light: '#E9E9EC',
    lightHover: '#A7A9AF',
    white: '#ffffff',
    black: '#000000',
    gray: '#0c0c0c',
    mediumGray: '#b4b4b4',
    lightGray: '#e4e4e4',
    lighterGray: '#efefef',
    veryLightGray: '#e9e9ec',
    appDarkGray: '#454654',
    appBlack: '#1b1c20',
    appDarkGrayLighter: '#34363c',
    appGray3: '#24252b',
    appLightGray: '#a7a9af',
    veryDark: '#343540',
    textGray: '#909398',
    error: '#F4333F',
  },
  achievementColor: {
    bronze: {
      dark: '#943c27',
      medium: '#ff8f63',
      light: '#ffd7bc',
    },
    silver: {
      dark: '#7d7d7d',
      medium: '#c9caca',
      light: '#ededed',
    },
    gold: {
      dark: '#a18803',
      medium: '#b79d07',
      light: '#fbf7e0',
    },
    legendary: {
      dark: '#853800',
      medium: '#be5300',
      light: '#f77100',
    },
    epic: {
      dark: '#721044',
      medium: '#bc1d8e',
      light: '#cc5fad',
    },
    rare: {
      dark: '#1e275e',
      medium: '#476dc3',
      light: '#7ebbe6',
    },
    uncommon: {
      dark: '#2d5c2b',
      medium: '#35ad4b',
      light: '#7ec97d',
    },
    common: {
      dark: '#515253',
      medium: '#898a8c',
      light: '#dfe0e1',
    },
  },
  mixins: {
    trans: () => `transition: all 0.25s ease-out;`,
  },
  sass: {
    // Named Colors
    hiber_red: '#E81623',
    hiber_green: '#09B584',
    hiber_yellow: '#C8C804',
    hiber_purple: '#6424CC',
    hiber_gray: '#3d3d3d',
    hiber_blue: '#00aeff',
    hiber_orange: '#ffbc2f',
    google_orange: '#e04a32',
    youtube_red: '#FF0000',
    // Descriptive Colors
    white: '#ffffff',
    black: '#191a1d',
    dark_gray: '#101010',
    gray: '#585858',
    app_dark_gray: '#454654',
    app_comment_gray: '#2d2f38',
    app_gray3: '#24252b',
    app_gray_button: '#292a31',
    medium_gray: '#b4b4b4',
    light_gray: '#d8d8d8',
    lighter_gray: '#efefef',
    // Functional Colors
    bg_color: '#191a1d',
    select_color: '#09B584',
    // Uix
    ui_font_size: '0.9rem',
    ui_font: '"Inter", sans_serif',
    ui_primary_color: '#fff',
    ui_select_color: '#09B584',

    ui_bg_color: '#2d2f38',
    ui_emphasized: '#09B584',
    // Dimensions
    top_bar_height: '80px',
    top_bar_height2: '107px',
    btn_radius: '8px',
    btn_small_radius: '8px',
    btn_height: '33px',
    small_btn_height: '20px',
    btn_margin: '3px',
    card_margin: '10px',
    // Breakpoints
    break_extra_small: '350px',
    break_small: '450px',
    break_medium: '700px',
    break_mediumPlus: '1200px',
    break_large: '1440px',
    break_mobile: '830px',
    top_break_point: '1000px',
    // Misc
    transition_dur: '25s',
    notchHeight: '35px',
    // Scrollbar
    scrollbar_thumb_color: '#E81623',
    scrollbar_width: '8px',
    scrollbar_radius: '5px',
  },
  socialMedia: {
    twitch: '#6441a5',
    youtube: '#ff0000',
    twitter: '#00aced',
    instagram: '#bc2a8d',
    tiktok: '#FE2C55',
  },
  functionalColor: {
    disabledBackground: '#cec9c9',
    disabledText: '#efefef',
  },
  maxWidth: '1440px',
  scrollbar: { thumbColor: '#c2c2c2' },
  // Transition
  transition: {
    fast: '0.25s',
    medium: '0.35s',
    slow: '0.45s',
  },
  boxShadow: {
    small: '1px 1px 3px rgba(0, 0, 0, 0.2)',
    medium: '0px 0px 5px rgba(61, 61, 61, 0.2)',
    gameCard: '0 0.5px 5px 1px rgba(0, 0, 0, 0.33)',
    gameCardInset: 'inset 0 0 3px 3px rgba(0, 0, 0, 0.03)',
    profilePicture: 'inset 0 0 5px rgba(0, 0, 0, 1)',
    menu: '0px 4px 16px rgba(17, 18, 22, 0.3)',
  },
  fontSize: {
    titleBigger: '55px',
    title: '32px',
    titleSmaller: '23px',
    menuNav: '16px',
    username: '19px',
    nav: '15px',
    text: '14px',
    info: '13px',
    small: '12px',
    label: '11px',
  },
  padding: {
    small: '5px',
    medium: '10px',
    large: '20px',
    xLarge: '40px',
  },
  margin: {
    small: '5px',
    medium: '10px',
    large: '20px',
    xLarge: '40px',
  },
  borderRadius: {
    small: '0.125rem',
    medium: '0.25rem',
    large: '0.5rem',
    xLarge: '1rem',
  },
  onlyMobile: `
    @media (min-width: ${breakpoint.mediumSmall}) and (orientation: portrait) {
      display: none;
    }
    @media (min-width: ${breakpoint.mediumLarge}) and (orientation: landscape) {
      display: none;
    }
  `,
  notMobile: `
  @media (max-width: ${breakpoint.mediumSmall}) and (orientation: portrait) {
    display: none;
  }
  @media (max-height: ${breakpoint.mediumSmall}) and (orientation: landscape) {
    display: none;
  }
`,
  colors,
  colorTheme,
  ...theme,
} as const;

export default Theme;
