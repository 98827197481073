import { FlexBox } from 'components/layouts/FlexBox';
import { GoogleSignIn } from 'components/globals/GoogleSignin/GoogleSignin';
import { Spacer } from 'components/layouts/Spacer';
import { Paragraph } from 'components/typography';
import { DynamicWalletSignInButton } from 'views/global/Wallet/DynamicWalletSignInButton';
import { LoginForm } from 'views/forms/LoginForm/LoginForm';
import { SigninMutation } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useLogin } from 'hooks/useLogin';
import { postMessageToEngine } from 'lib/postMessageToEngine';
import { getAgeFromBirthdate } from 'utils/getAgeFromBirthdate';
import { Modal } from '../Modal';
import * as S from '../Modal.styles';
import { LoginModalProps } from './AuthModal.types';
import { DEFAULT_LOGIN_HEADLINE, DEFAULT_SIGN_UP_HEADLINE } from './AuthModal';

export const LoginModal = ({
  navigateToForgotPassword,
  navigateToSignUp,
  navigateToUsername,
  onLoginCompleted,
  onClose,
  title,
}: LoginModalProps) => {
  const dive = useDive();
  const { login } = useLogin();

  const onLoginSuccess = async (authResponse: SigninMutation['loginUser']) => {
    const loginInput = {
      accessToken: authResponse?.accessToken,
      refreshToken: authResponse?.refreshToken,
      userId: authResponse?.user?.id,
    };

    await login(loginInput);
    await onLoginCompleted?.();
    onClose?.();
  };

  const onAlternateSignInComplete = () => {
    postMessageToEngine('LOGIN_SUCCESS');
    onClose?.();
    onLoginCompleted?.();
  };

  const onNavigateToSignUp = () => {
    dive.trackModalOpen({ name: 'sign_up', origin: 'login', description: DEFAULT_SIGN_UP_HEADLINE });
    navigateToSignUp();
  };

  const onSubmit = async (loginUser: SigninMutation['loginUser']) => {
    const user = loginUser?.user;

    if (user) {
      dive.trackUserLoggedIn({
        username: user.username,
        age: getAgeFromBirthdate(user.birthdate),
        publishedGames: user.totalPublishedGames || 0,
        loginType: 'user',
        authId: '',
        user,
      });
    }
    await onLoginSuccess(loginUser);
  };

  return (
    <Modal onClose={onClose}>
      <S.RpmFrontLight width="160px" top="-103.5px" src="https://cdn.hibervr.com/static/images/Rpm_01_FrontLight.png" />
      <S.Headline>{title ?? DEFAULT_LOGIN_HEADLINE}</S.Headline>
      <Spacer height={40} />
      <LoginForm onLoginSuccess={onSubmit} onForgotPassword={navigateToForgotPassword} />
      <FlexBox alignItems="center">
        <S.Line />
        <Paragraph size="medium" margin="1rem 0">
          or
        </Paragraph>
        <S.Line />
      </FlexBox>
      <Spacer height={8} />
      <GoogleSignIn navigateToUsername={navigateToUsername} onCompleted={onAlternateSignInComplete} />
      <Spacer height={16} />
      <DynamicWalletSignInButton navigateToUsername={navigateToUsername} onCompleted={onAlternateSignInComplete} />
      <Spacer height={40} />
      <FlexBox justifyContent="center">
        <S.Text fontSize="16px">
          Don&apos;t have an account?{' '}
          <S.Link fontSize="16px" color="white" bold onClick={onNavigateToSignUp}>
            Sign up
          </S.Link>
        </S.Text>
      </FlexBox>
    </Modal>
  );
};
