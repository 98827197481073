import {
  Close,
  Friends,
  Globe2,
  Logout,
  Profile,
  Report,
  Settings,
  Sort,
  Trophy,
  User,
  Wallet as WalletIcon,
  Tag,
} from '@hiberworld/icons';
import { Button } from 'components/common/buttons/Button';
import { CircularImage } from 'components/common/images/CircularImage';
import { FlexBox } from 'components/layouts/FlexBox';
import { HamburgerButton } from 'views/global/HamburgerMenu/HamburgerButton/HamburgerButton';
import { Select } from 'components/inputs/Select/Select';
import { Box } from 'components/layouts/Box/Box';
import { DEFAULT_LOGIN_HEADLINE, DEFAULT_SIGN_UP_HEADLINE } from 'components/legacy/modals/AuthModal/AuthModal';
import { useAuthModal } from 'components/legacy/modals/AuthModal/useAuthModal';
import { FeatureTogglesModal } from 'components/legacy/modals/FeatureTogglesModal/FeatureTogglesModal';
import { Spacer } from 'components/layouts/Spacer';
import { Heading, Label } from 'components/typography';
import { VerifiedBadge } from 'components/globals/VerifiedBadge';
import { useWalletEnabled } from 'views/global/Wallet/useWalletEnabled';
import { usePageQuery, useProjectFeedPageQuery, UserType } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useHasUnclaimedLoot } from 'hooks/useHasUnclaimedLoot';
import { useMe } from 'hooks/useMe';
import { usePressOutside } from 'hooks/usePressOutside';
import { useModal } from 'lib/useModal';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTheme } from 'styled-components';
import { getPageSlugFromRoute } from 'utils/getPageSlugFromRoute';
import { isModeratorUser } from 'utils/userRoles';
import ProgressBar from '../../../components/common/ProgressBar/ProgressBar';
import * as S from './HamburgerMenu.styles';
import { MenuItem } from './MenuItem';
import { SmallMenuItem } from './SmallMenuItem';
import { Toggle } from 'components/inputs/Toggle/Toggle';
import { useFeatureToggle } from 'lib/useFeatureToggles';
import { FEATURE_TOGGLE_SHOP } from 'constants/featureToggle.constants';

const WalletModal = dynamic(() => import('views/global/Wallet/WalletModal'), { ssr: false, loading: () => null });

export const HamburgerMenu = () => {
  const { isLoggedIn, me, logout, setAdminUserType, adminUserType } = useMe();
  const theme = useTheme();
  const featureTogglesModal = useModal(<FeatureTogglesModal />);
  const router = useRouter();
  const showUnclaimedLootIndicator = useHasUnclaimedLoot();
  const [open, setIsOpen] = useState(false);

  const ref = usePressOutside({
    callback: () => {
      setIsOpen(false);
    },
  });

  const toggleMenu = () => {
    setIsOpen(open => !open);
  };

  const dive = useDive();

  const [showAdminPanel, setShowAdminPanel] = useState(Boolean(adminUserType) && adminUserType !== null);
  const slug = getPageSlugFromRoute(router.asPath);

  const { refetch: refetchPage } = usePageQuery({
    variables: { slug, previewUserType: adminUserType },
    fetchPolicy: 'network-only',
    skip: true,
  });
  const { refetch: refetchFeedPage } = useProjectFeedPageQuery({
    variables: { slug, preview: Boolean(adminUserType) },
    fetchPolicy: 'network-only',
    skip: true,
  });

  const tabAble = open ? 1 : -1;

  const authModal = useAuthModal();

  const { isEnabled: shopEnabled } = useFeatureToggle(FEATURE_TOGGLE_SHOP);

  const { enabled: walletEnabled } = useWalletEnabled();
  const walletModal = useModal(<WalletModal />);

  const refetchPageData = () => {
    refetchPage();
    refetchFeedPage();
  };

  const showShop = shopEnabled;
  const showWallet = walletEnabled && isLoggedIn;
  const showSettings = isLoggedIn;
  const showModeration = isLoggedIn && isModeratorUser(me);
  const showSeparator = showWallet || showSettings || showModeration;
  const levelProgress = ((me?.currentXp ?? 0) / (me?.xpToNextLevel ?? 1)) * 100 ?? 0;

  return (
    <>
      <HamburgerButton
        showNotificationIndicator={showUnclaimedLootIndicator}
        expanded={open}
        onClick={e => {
          e.stopPropagation();
          toggleMenu();
        }}
      />
      <S.HamburgerMenu ref={ref} id="hamburger-menu" data-testid="hamburger-menu" data-cy="hamburgerMenu" open={open}>
        {isLoggedIn && (
          <>
            <S.UserInfo prefetch={false} href={`/user/${me?.username}/achievements`} passHref onClick={toggleMenu}>
              <S.ProfileContainer>
                <CircularImage size={40} src={me?.pictureUrl} data-cy="hamburgerMenuProfileImage" />
                <S.Username nameWidth={me?.username?.length ?? 0} gap="2px">
                  <FlexBox alignItems="center" gap="4px" data-cy="hamburgerMenuUsername">
                    <Heading size="h6">{`${me?.username}`}</Heading>
                    <VerifiedBadge isEmployee={me?.isEmployee} isVerified={me?.isVerified} />
                  </FlexBox>
                  <Label
                    color={theme.primaryColor.textGray}
                    size="specialSmall"
                    data-cy="hamburgerMenuLevel">{`Level ${me?.level}`}</Label>
                </S.Username>
              </S.ProfileContainer>
              <ProgressBar
                data-cy="hamburgerMenuProgressBar"
                progress={levelProgress}
                htmlTitle={`${me?.currentXp} / ${me?.xpToNextLevel}`}
                progressColor="linear-gradient(-90deg, #BD3FD1, #814CC7)"
              />
              <Spacer height="0.5rem" />
              <Label
                color={theme.primaryColor.textGray}
                size="medium"
                data-cy="hamburgerMenuNextLevel"
                style={{ cursor: 'pointer' }}>
                {me?.currentXp} / {me?.xpToNextLevel} XP
              </Label>
            </S.UserInfo>
            <Spacer height="1rem" />
          </>
        )}

        {!isLoggedIn && (
          <Box margin="0 1.75rem 1rem">
            <Button
              size="medium"
              text="SIGN UP FOR FREE"
              variety="primary"
              onClick={() => {
                dive.trackModalOpen({
                  origin: 'hamburger_menu',
                  name: 'sign_up',
                  description: DEFAULT_SIGN_UP_HEADLINE,
                });
                toggleMenu();
                authModal.open('SIGN_UP');
              }}
            />
          </Box>
        )}

        <MenuItem
          hidden={!isLoggedIn}
          href={`/user/${me?.username}`}
          Icon={<Profile size="1.5rem" title="Profile" color={theme.primaryColor.white} />}
          label="Profile"
          onClick={toggleMenu}
          tabAble={tabAble}
        />

        <MenuItem
          hidden={!isLoggedIn}
          href="/creations"
          label="My Worlds"
          onClick={toggleMenu}
          tabAble={tabAble}
          Icon={<Globe2 size="1.5rem" title="My Worlds" color={theme.primaryColor.white} />}
        />

        <MenuItem
          href="/avatar"
          label="Avatar"
          onClick={toggleMenu}
          tabAble={tabAble}
          Icon={<User size="1.5rem" color={theme.primaryColor.white} />}
        />

        <MenuItem
          href={isLoggedIn ? `/user/${me?.username}/achievements` : '/achievements'}
          label="Achievements"
          onClick={toggleMenu}
          tabAble={tabAble}
          Icon={
            <FlexBox position="relative">
              <Trophy size="1.5rem" title="Achievements" color={theme.primaryColor.white} />
              {showUnclaimedLootIndicator && (
                <FlexBox
                  position="absolute"
                  right="-2px"
                  width="10px"
                  height="10px"
                  backgroundColor={theme.colors.text.error}
                  borderRadius="8px"
                />
              )}
            </FlexBox>
          }
        />

        <MenuItem
          hidden={isLoggedIn}
          Icon={<Logout size="1.5rem" title="Log in" color={theme.primaryColor.white} />}
          label="Log In"
          onClick={() => {
            dive.trackModalOpen({ origin: 'hamburger_menu', name: 'login', description: DEFAULT_LOGIN_HEADLINE });
            authModal.open('LOGIN');
            toggleMenu();
          }}
          tabAble={tabAble}
        />

        <MenuItem
          hidden={!isLoggedIn}
          href={`/user/${me?.username}/friends`}
          label="Friends"
          onClick={toggleMenu}
          Icon={<Friends size="1.5rem" color={theme.primaryColor.white} />}
          tabAble={tabAble}
        />

        {showSeparator && (
          <>
            <Spacer height="0.5rem" />
            <S.Line />
            <Spacer height="0.5rem" />
          </>
        )}

        <MenuItem
          hidden={!showShop}
          href="/shop"
          label="Shop"
          onClick={toggleMenu}
          tabAble={tabAble}
          Icon={<Tag size="1.5rem" title="Tag" color={theme.primaryColor.white} />}
        />

        <MenuItem
          hidden={!showWallet}
          tabAble={tabAble}
          label="Wallets"
          Icon={<WalletIcon size="1.5rem" title="Wallets" color={theme.primaryColor.white} />}
          onClick={() => {
            dive.trackModalOpen({ origin: 'hamburger_menu', name: 'wallet', description: '' });
            toggleMenu();
            walletModal.open();
          }}
        />
        <MenuItem
          hidden={!showSettings}
          href="/update-profile"
          label="Settings"
          onClick={toggleMenu}
          tabAble={tabAble}
          Icon={<Settings size="1.5rem" title="Settings" color={theme.primaryColor.white} />}
        />

        <MenuItem
          hidden={!showModeration}
          href="/moderation/reports"
          label="Moderation"
          onClick={toggleMenu}
          Icon={<Report size="1.5rem" title="Moderation" color={theme.primaryColor.white} />}
          tabAble={tabAble}
        />

        <Spacer height="0.5rem" />
        <S.Line />
        <Spacer height="1rem" />

        <S.SmallGrid>
          <SmallMenuItem
            href="https://www.hiber3d.com/about"
            target="_blank"
            label="About"
            onClick={toggleMenu}
            tabAble={tabAble}
          />

          <SmallMenuItem
            href="https://hiber3d.com/terms-of-use"
            label="Terms of Use"
            target="_blank"
            onClick={toggleMenu}
            tabAble={tabAble}
          />

          <SmallMenuItem href="/contact" target="_blank" label="Help" onClick={toggleMenu} tabAble={tabAble} />

          <SmallMenuItem
            href="https://hiber3d.com/contentcreatorpolicy"
            label="Content Creator Policy"
            target="_blank"
            onClick={toggleMenu}
            tabAble={tabAble}
          />

          <SmallMenuItem
            href="https://hiber3d.com/news"
            label="Press"
            onClick={toggleMenu}
            target="_blank"
            tabAble={tabAble}
          />

          <SmallMenuItem
            href="https://hiber3d.com/privacy-policy"
            label="Privacy Policy"
            target="_blank"
            onClick={toggleMenu}
            tabAble={tabAble}
          />

          <SmallMenuItem
            href="https://hiber3d.com/faq"
            label="FAQ"
            target="_blank"
            onClick={toggleMenu}
            tabAble={tabAble}
          />

          <SmallMenuItem
            href="/cookies?hideCookieBanner=true"
            label="Cookie Policy"
            target="_blank"
            onClick={toggleMenu}
            tabAble={tabAble}
          />

          <SmallMenuItem
            href="https://shop.hiberworld.com/"
            label="Merch"
            target="_blank"
            onClick={toggleMenu}
            tabAble={tabAble}
          />

          <SmallMenuItem
            hidden={!isLoggedIn || !isModeratorUser(me)}
            href="/boss/start"
            label="Boss"
            onClick={toggleMenu}
            red
            tabAble={tabAble}
          />
        </S.SmallGrid>
        {isLoggedIn && (
          <S.SmallGrid marginTop="0.75rem">
            <SmallMenuItem
              hidden={!isLoggedIn}
              label="Log Out"
              Icon={<Logout size={24} />}
              onClick={() => {
                toggleMenu();
                logout();
              }}
              tabAble={tabAble}
            />
          </S.SmallGrid>
        )}
        {me?.isEmployee && (
          <FlexBox gap="1rem" padding="1rem 2rem 0">
            <Label size="medium" htmlFor="admin-mode">
              Show admin panel
            </Label>
            <Toggle
              title="admin-panel"
              backgroundColor={theme.primaryColor.appDarkGray}
              id="admin-mode"
              checked={showAdminPanel}
              onChange={e => setShowAdminPanel(e.target.checked)}
            />
          </FlexBox>
        )}
        <Spacer height="1.75rem" />

        {showAdminPanel && (
          <S.AdminPanel>
            <FlexBox alignItems="center" gap="0.25rem" padding="0 0.5rem">
              <Label size="small" htmlFor="user-role">
                Preview page as
              </Label>
              <Select
                value={adminUserType ?? ''}
                id="user-role"
                onChange={e => setAdminUserType(e.target.value as UserType)}>
                <option value="">Choose type</option>
                <option value={UserType.Guest}>Guest</option>
                <option value={UserType.Nonregistered}>Non-Registered</option>
                <option value={UserType.Registered}>Registered</option>
              </Select>
              <S.CloseIcon role="button">
                <Sort title="Reload" onClick={refetchPageData} />
              </S.CloseIcon>
              <S.CloseIcon role="button">
                <Close title="Clear" size="1rem" onClick={() => setAdminUserType(null)} />
              </S.CloseIcon>
            </FlexBox>
            <MenuItem
              Icon={<Settings size="1.5rem" color={theme.primaryColor.white} />}
              hidden={!me?.isEmployee}
              onClick={() => featureTogglesModal.open()}
              tabAble={tabAble}
              label="My Feature Toggles"
            />
          </S.AdminPanel>
        )}
      </S.HamburgerMenu>
    </>
  );
};
