import { DiveSDK } from '@divegames/dive-ts-web-sdk';
import cookie from 'cookie';
import { AvatarProvider, CurrentUserFragment, GameKit, PrivacySettings, User } from 'generated/graphql';
import { useMe } from 'hooks/useMe';
import { useIsEmbeddedInEngine } from 'lib/useIsEmbeddedInEngine';
import { useSearchParam } from 'lib/useSearchParam';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DeepPartial } from 'react-hook-form';
import { createRandomUserId } from 'utils/createRandomUserId';
import { isClientSide } from 'utils/isClientSide';
import { isSearchCrawler } from 'utils/isSearchCrawler';
import { DiveContentId, DiveDMType, DiveEvent, DiveEvents, DivePageId, DiveUserInteractType } from './types/DiveEvents';
import { TrackUserCreated, TrackUserLoggedInProps, TrackUserSignedUpProps } from './types/props';
import {
  getPreviousPageData,
  getPrivacySettingsAsString,
  getProjectTypeFromEngineVersionAndGameKit,
  setPreviousPageData,
} from './useDive.helpers';
import { DiveUserType } from './useDive.types';

export const useDive = () => {
  const { me } = useMe();
  const cookies = cookie.parse(isClientSide() ? document.cookie : '');
  const router = useRouter();
  const worldId = useSearchParam('worldId');
  const { isEmbeddedInEngine } = useIsEmbeddedInEngine();
  const isInEngine = isEmbeddedInEngine();

  const [appLaunched, setAppLaunched] = useState(false);

  useEffect(() => {
    const fn = () => setAppLaunched(DiveSDK.getInstance().appLauchedSent);
    const interval = setInterval(fn, 1000);

    return () => clearInterval(interval);
  }, []);

  const updateHeader = useCallback(
    (currentUser?: Partial<CurrentUserFragment | DeepPartial<User>>) => {
      const user = currentUser ?? me;
      const instance = DiveSDK.getInstance();
      let playerId = cookies.userId || user?.id || cookies.rid;

      const registeredUserType = user?.isRegistered ? DiveUserType.registeredUser : DiveUserType.nonRegisteredUser;

      if (!playerId) {
        playerId = createRandomUserId();
      }

      try {
        instance
          .SetServerSessionId(user?.session?.id || '')
          .SetPlayerXp(user?.xp || 0)
          .SetPlayerId(playerId || 'unknown')
          .SetPlayerLevel(user?.level || 0)
          .SetCustomHeaderParam('type_user_id', user ? registeredUserType : DiveUserType.guestUser)
          .SetCustomHeaderParam('event_source', isInEngine ? 'engine' : 'platform')
          .SetCustomHeaderParam('soft_currency_bal', user?.softCurrency || 0);
        if (worldId) {
          instance.SetCustomHeaderParam('id', worldId || '');
        }
      } catch (error) {
        console.error('Could not set headers properly', error);
      }
    },
    [me, cookies.userId, cookies.rid, isInEngine, worldId]
  );

  const track = useCallback(
    <T extends DiveEvent>(eventName: T, eventProperties: DiveEvents[T], user?: DeepPartial<User>) => {
      if (!isClientSide() || isSearchCrawler()) {
        return;
      }

      updateHeader(user);

      const instance = DiveSDK.getInstance();

      if (!instance.appLauchedSent) {
        instance.Launch();
      }

      instance.RecordEvent(eventName, eventProperties ?? {});
    },
    [updateHeader]
  );

  const query = useMemo(() => {
    return router?.query ?? {};
  }, [router?.query]);

  const trackUserPageOpened = useCallback(() => {
    if (!isClientSide() || !router?.asPath) {
      return;
    }

    const previousPage = getPreviousPageData();

    if (previousPage.url === router.asPath) {
      return;
    }

    const params = {
      page_id: router.asPath,
      page_id_before: previousPage.url,
      duration: (Date.now() - previousPage.timeStamp) / 1000,
    };

    for (const entry of Object.entries(query)) {
      const [key, value] = entry;
      params[key] = value;
    }

    track('userPageOpened', params);

    setPreviousPageData(router.asPath);
  }, [query, router.asPath, track]);

  const userProfileEdited = useCallback(
    (
      user: Pick<User, 'id' | 'isVerified' | 'isEmployee' | 'username'> & {
        getOrCreatePrivacySettings?:
          | Pick<PrivacySettings, 'achievementPublicity' | 'friendsArePublic' | 'onlyFriendsInMultiplayer'>
          | undefined
          | null;
      },
      properties?: Partial<DiveEvents['userProfileEdited']>
    ) => {
      const diveProperties: DiveEvents['userProfileEdited'] = {
        see_friends: getPrivacySettingsAsString(user?.getOrCreatePrivacySettings?.friendsArePublic ?? 0),
        multiplayer_join: user?.getOrCreatePrivacySettings?.onlyFriendsInMultiplayer ? 'friendsOnly' : 'everyone',
        see_achievements: getPrivacySettingsAsString(user?.getOrCreatePrivacySettings?.achievementPublicity ?? 0),
        is_verified: user.isVerified ? 1 : 0,
        is_employee: user.isEmployee ? 1 : 0,
        player_id: user.id,
        username: user.username,
        ...properties,
      };

      track('userProfileEdited', diveProperties);
    },
    [track]
  );

  const trackUserFeaturedAdded = useCallback(
    (properties: DiveEvents['userFeaturedAdded']) => {
      track('userFeaturedAdded', properties);
    },
    [track]
  );

  const trackUserFeaturedRemoved = useCallback(
    (properties: DiveEvents['userFeaturedRemoved']) => {
      track('userFeaturedRemoved', properties);
    },
    [track]
  );

  const trackDmMessageOpened = useCallback(
    (type: DiveDMType, senderId: string, unread_msg_count: number) => {
      track('dmMessageOpened', { type, conversation_id: senderId, unread_msg_count });
    },
    [track]
  );

  const trackDmMessageReceived = useCallback(
    (type: DiveDMType, senderId: string) => {
      track('dmMessageReceived', { type, conversation_id: senderId });
    },
    [track]
  );

  const trackDmMessageSent = useCallback(
    (type: DiveDMType, receiverId: string) => {
      track('dmMessageSent', { type, conversation_id: receiverId });
    },
    [track]
  );

  const trackDmScreenOpened = useCallback(() => {
    track('dmScreenOpened', null);
  }, [track]);

  const trackUserAvatarSelected = useCallback(
    (id: string, provider: AvatarProvider) => {
      track('userAvatarSelected', { name: id, origin: provider });
    },
    [track]
  );

  const trackDmUserSettingsEdited = useCallback(
    (friend_username: string, action: string, friend_id: number | string, is_enabled) => {
      track('dmUserSettingsEdited', {
        action,
        friend_id,
        is_enabled,
        username: friend_username,
      });
    },
    [track]
  );

  const trackUserLike = useCallback(
    (
      projectId: string,
      type: 'game' | 'comment',
      username: string,
      page_id: string,
      authorId: string,
      commentLength?: number
    ) => {
      track('userLike', { type, id: projectId, username, page_id, length: commentLength, player_id: authorId });
    },
    [track]
  );

  const trackUserFollow = useCallback(
    (userId: string, username: string, page_id: string) => {
      track('userFollow', { id: userId, username, page_id });
    },
    [track]
  );

  const trackUserUnFollow = useCallback(
    (userId: string, username: string, page_id: string) => {
      track('userUnFollow', { id: userId, username, page_id });
    },
    [track]
  );

  const trackUserLoggedIn = useCallback(
    ({ username, age, publishedGames, loginType, authId, user, idType }: TrackUserLoggedInProps) => {
      const properties: DiveEvents['userLoggedIn'] = {
        username,
        published_games: publishedGames,
        id: authId,
        login_type: loginType,
        birth_age: age,
        id_type: idType,
      };
      track('userLoggedIn', properties, user);
    },
    [track]
  );

  const trackUserCreated = useCallback(
    ({ guestId, age, user }: TrackUserCreated) => {
      const properties: DiveEvents['userCreated'] = {
        guest_player_id: guestId,
        birth_age: age,
      };
      track('userCreated', properties, user);
    },
    [track]
  );

  const trackUserSignedUp = useCallback(
    ({ newUserId, loginType, thirdPartyId, username, user, idType }: TrackUserSignedUpProps) => {
      const properties: DiveEvents['userSignedUp'] = {
        new_player_id: newUserId,
        id: thirdPartyId,
        id_type: idType,
        login_type: loginType,
        username,
      };
      track('userSignedUp', properties, user);
    },
    [track]
  );

  const trackUserInteract = useCallback(
    (
      page_id: DivePageId,
      page_id_before: DivePageId,
      name: string,
      type: DiveUserInteractType,
      id?: string,
      reason?: string
    ) => {
      track('userInteract', {
        page_id,
        page_id_before,
        name,
        type,
        id,
        reason,
      });
    },
    [track]
  );

  const trackProductInteract = useCallback(
    ({
      id,
      name,
      type,
      reason,
      description,
      price,
    }: {
      id: string;
      name: string;
      type: string;
      reason: 'equip' | 'unlock' | 'buy';
      description?: string;
      price?: number;
      currency?: string;
    }) => {
      track('userInteract', {
        page_id: router.asPath,
        page_id_before: '',
        name,
        type: 'button',
        id,
        reason,
        item_type: type,
        description,
        price,
      });
    },
    [router.asPath, track]
  );

  const trackCorrelatedButtonInteract = useCallback(
    (name: string, type: DiveUserInteractType, correlation_id: string) => {
      track('userInteract', {
        name,
        type,
        correlation_id,
        page_id: router.asPath,
        page_id_before: '',
      });
    },
    [track]
  );

  const trackOnlineFriendChatBubbleInteract = useCallback(
    ({ id, name, reason, player_id }: { id: string; name: string; reason?: string; player_id: string }) => {
      track('userInteract', {
        page_id: router.asPath,
        page_id_before: '',
        name,
        type: 'button',
        id,
        reason,
        player_id,
      });
    },
    [router.asPath, track]
  );

  const trackOnlineFriendChatJoin = useCallback(
    ({ id, name, reason, player_id }: { id: string; name: string; reason?: string; player_id: string }) => {
      track('userInteract', {
        page_id: router.asPath,
        page_id_before: '',
        name,
        type: 'button',
        id,
        reason,
        player_id,
      });
    },
    [router.asPath, track]
  );

  const trackOnlineFriendGamePage = useCallback(
    ({ id, name, reason, player_id }: { id: string; name: string; reason?: string; player_id: string }) => {
      track('userInteract', {
        page_id: router.asPath,
        page_id_before: '',
        name,
        type: 'button',
        id,
        reason,
        player_id,
      });
    },
    [router.asPath, track]
  );

  const trackUserContentViewed = useCallback(
    (page_id: DivePageId, content_id: DiveContentId, type: DiveUserInteractType) => {
      track('userContentViewed', {
        page_id,
        content_id,
        type,
      });
    },
    [track]
  );

  const trackErrorCreatedCustom = useCallback(
    (code: string, page_id: string, description: string, correlation_id: string) => {
      track('errorCreatedCustom', { code, page_id, description, correlation_id });
    },
    [track]
  );

  const trackUserComment = useCallback(
    (player_id: string | null, game_id: string, reply: boolean, length: number) => {
      track('userComment', { player_id, game_id, reply: reply ? '1' : '0', length, page_id: router.asPath });
    },
    [router?.asPath, track]
  );

  const trackUserCommentDeleted = useCallback(
    (player_id: string, game_id: string, reply: boolean, length: number) => {
      track('userCommentDeleted', { player_id, game_id, reply: reply ? '1' : '0', length });
    },
    [track]
  );

  const trackCreatePublished = useCallback(
    (
      game_id: string,
      engineVersion: number,
      gameKit: GameKit,
      project_name: string,
      is_comments_on: boolean,
      tags?: string[],
      thumbnail_count?: number
    ) => {
      const type = getProjectTypeFromEngineVersionAndGameKit(gameKit, engineVersion);

      track('createPublished', {
        id: game_id,
        type,
        name: project_name,
        is_comments_on,
        tags: tags ?? [],
        thumbnail_count: thumbnail_count ?? 0,
      });
    },
    [track]
  );

  const trackCreateInit = useCallback(
    (projectId: string, templateId?: string) => {
      track('createInit', { id: projectId, template_id: templateId });
    },
    [track]
  );

  const trackGameInit = useCallback(
    (projectId: string) => {
      track('gameInit', { id: projectId, page_id: router.asPath });
    },
    [router.asPath, track]
  );

  const trackGameGenerationComplete = useCallback(
    (
      name: string,
      type: string,
      duration: number,
      session_id: string,
      correlation_id: string,
      strategy: string,
      endpoint_ver: string,
      creator_ver: string
    ) =>
      track('gameGenerationComplete', {
        name,
        type,
        duration,
        session_id,
        correlation_id,
        strategy,
        endpoint_ver,
        creator_ver,
      }),
    [track]
  );

  const trackUserFriendRequestSent = useCallback(
    (username: string, page_id: string) => {
      track('userFriendRequestSent', { page_id, username });
    },
    [track]
  );

  const trackUserFriendRequestAccepted = useCallback(
    (username: string, page_id: string) => {
      track('userFriendRequestAccepted', { page_id, username });
    },
    [track]
  );

  const trackDMGroupAction = useCallback(
    (conversation_id: string, type: DiveEvents['dmGroupAction']['type'], player_id?: string) => {
      track('dmGroupAction', { conversation_id, type, player_id });
    },
    [track]
  );

  const trackModalOpen = useCallback(
    ({ origin, name, description }: Omit<DiveEvents['userModalOpened'], 'page_id'>) => {
      track('userModalOpened', {
        page_id: router.asPath,
        name,
        description,
        origin,
      });
    },
    [track]
  );

  const trackAchievementsLadderScroll = useCallback(
    ({ name }: { name: string }) => {
      track('userInteract', {
        page_id: router.asPath,
        page_id_before: '',
        name,
        type: 'button',
      });
    },
    [router.asPath, track]
  );

  const trackAchievementsFocus = useCallback(
    ({ id, type, item_type }: { id: string; type: 'button' | 'scroll'; item_type: 'avatar' | 'empty' }) => {
      track('userInteract', {
        page_id: router.asPath,
        page_id_before: '',
        name: 'level_selected',
        id,
        type,
        item_type,
      });
    },
    [router.asPath, track]
  );

  return useMemo(
    () => ({
      appLaunched,
      track,
      trackAchievementsFocus,
      trackAchievementsLadderScroll,
      trackCorrelatedButtonInteract,
      trackCreateInit,
      trackCreatePublished,
      trackDMGroupAction,
      trackDmMessageOpened,
      trackDmMessageReceived,
      trackDmMessageSent,
      trackDmScreenOpened,
      trackDmUserSettingsEdited,
      trackErrorCreatedCustom,
      trackGameGenerationComplete,
      trackGameInit,
      trackModalOpen,
      trackOnlineFriendChatBubbleInteract,
      trackOnlineFriendChatJoin,
      trackOnlineFriendGamePage,
      trackProductInteract,
      trackUserAvatarSelected,
      trackUserComment,
      trackUserCommentDeleted,
      trackUserContentViewed,
      trackUserCreated,
      trackUserFeaturedAdded,
      trackUserFeaturedRemoved,
      trackUserFollow,
      trackUserFriendRequestAccepted,
      trackUserFriendRequestSent,
      trackUserInteract,
      trackUserLike,
      trackUserLoggedIn,
      trackUserPageOpened,
      trackUserSignedUp,
      trackUserUnFollow,
      updateHeader,
      userProfileEdited,
    }),
    [
      appLaunched,
      track,
      trackAchievementsFocus,
      trackAchievementsLadderScroll,
      trackCorrelatedButtonInteract,
      trackCreateInit,
      trackCreatePublished,
      trackDMGroupAction,
      trackDmMessageOpened,
      trackDmMessageReceived,
      trackDmMessageSent,
      trackDmScreenOpened,
      trackDmUserSettingsEdited,
      trackErrorCreatedCustom,
      trackGameGenerationComplete,
      trackGameInit,
      trackModalOpen,
      trackOnlineFriendChatBubbleInteract,
      trackOnlineFriendChatJoin,
      trackOnlineFriendGamePage,
      trackProductInteract,
      trackUserAvatarSelected,
      trackUserComment,
      trackUserCommentDeleted,
      trackUserContentViewed,
      trackUserCreated,
      trackUserFeaturedAdded,
      trackUserFeaturedRemoved,
      trackUserFollow,
      trackUserFriendRequestAccepted,
      trackUserFriendRequestSent,
      trackUserInteract,
      trackUserLike,
      trackUserLoggedIn,
      trackUserPageOpened,
      trackUserSignedUp,
      trackUserUnFollow,
      updateHeader,
      userProfileEdited,
    ]
  );
};
